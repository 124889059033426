<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Special Car Park Rate</v-card-title>
        <v-container fluid>
            <v-row>
                <!--
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('system_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create System
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                -->
                <v-btn-toggle class="col-12 offset-md-6 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Car Parks"
                    label="Car Park"
                    v-model="carpark.selected"
                    @change="execFilter('carpark')"
                    :items="carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="carpark.rule"
                    :loading="carpark.loader"
                    :disabled="carpark.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('carpark_specialrateadd')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click.prevent="createItem()"><v-icon left x-small dark>fa fa-cogs</v-icon>Create Special Car Park Rate
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" md="12" id="table-parent">
                    <div id="wrapper1">
                        <div id="div1">
                        </div>
                    </div>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        sort-by="GateID"
                        sort-desc
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkName}}</td>
                            <td>{{props.item.SpecialRateCardID}}</td>
                            <td>{{props.item.RateCardID}}</td>
                            <td>
                                <v-tooltip top v-if="props.item.SpecialRateCardID !== null">
                                    <template v-slot:activator="{on}">
                                        <a v-if="validateAccess('carpark_specialrateupdate')"
                                        style="text-decoration: underline;"
                                        id="custom-hover"
                                        v-on="on"
                                        @click.prevent="viewMore(props.item, 'update')">
                                        {{props.item.RateCardName}}
                                        </a>
                                        <p v-else>{{ props.item.RateCardName }}</p>
                                    </template>
                                    <span>Rate Card Info</span>
                                </v-tooltip>
                                <span v-else>N/A</span>
                                
                            </td>
                            <td>{{props.item.StartDateTime}}</td>
                            <td>{{props.item.EndDateTime}}</td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.Status, 'color')"
                                text-color="white">
                                    {{statusCheck(props.item.Status, 'text')}}
                                </v-chip>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" :operator="operator.itemsFull" :carpark="carpark.itemsFull" :ratecard="ratecardDetail.itemsFull" @confirm="onConfirm" @closed="onClosed"></create>
        <update :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" :operator="operator.itemsFull" :carpark="carpark.itemsFull" :ratecard="ratecard.itemsFull" @confirm="onConfirm" @closed="onClosed"></update>
    </div>
</template>

<script>
import * as moment from "moment";
import create from '.././modals/CreateSpecialRate';
import update from '.././modals/UpdateSpecialRateCard';
import {ERR} from '../../assets/js/errhandle.js';
import {rule} from '../../assets/js/checkPerm';
import * as AppStorage from '../../assets/js/storage';

export default {
    name: 'SpecialCarParkRate',
    title: 'Special Car Park Rate',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park',
                disabled: true,
                href: '#',
                },

                {
                text: 'Special Car Park Rate',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'CP ID', value: 'CarParkID', width: '3%' },
                { text: 'Car Park', value: 'CarParkName', width: '3%' },
                { text: 'Special Rate Card ID', value: 'SpecialRateCardID', width: '3%' },
                { text: 'Rate Card ID', value: 'RateCardID', width: '3%' },
                { text: 'Rate Card Name', value: 'RateCardName', width: '3%' },
                { text: 'Start Date', value: 'StartDateTime', width: '3%' },
                { text: 'End Date', value: 'EndDateTime', width: '3%' },
                { text: 'Status', value: 'ActiveStatus', width: '3%' },
            ],
            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            ratecard: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            ratecardDetail: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            tableloading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                modal_updateentry: false,
                modal_reset: false,
                readonly: true,
                allInfo: [],

                modal_viewconnection: false,
                modal_viewinfo: false,
            },
            totalrecords: '',
            btn_loading: false
        }
    },

    components: {create, update},

    watch: {
        page: {
            handler: function(value){
                this.items = [];
                this.listItems(1);
            }
        },
        'search': {
            handler: function(value){
                this.searchTimeOut();
            }
        }
    },

    async created(){
        await this.getOperatorFull();
        await this.getCarParkFull();
        await this.getRateCard();
        await this.getRateCardDetail();
        window.addEventListener("unload", (evt) => this.onUnload({carpark: this.carpark.selected, ratecard: this.ratecard.selected}, evt));
        this.carpark.selected = JSON.parse(AppStorage.get('filter')).carpark;
        this.ratecard.selected = JSON.parse(AppStorage.get('filter')).ratecard;
        await this.listItems();
        this.horizontalScroll();
    },

    methods: {
        async listItems(pg){
            this.items = [];
            this.btn_loading = true;

            try{
                this.tableloading = true;
                console.log(this.search)
                let response = await this.$store.dispatch("getSpecialRateCard", {carparkid: this.carpark.selected, ratecardid: this.ratecard.selected, search: this.search });
                
                if (response.data.code == 'AP000'){
                let dt = response.data.record;

                for(var i=0; i<dt.length; i++){
                    if(this.carpark.itemsFull.find(itm => itm.CarParkID === dt[i].CarParkID)){
                        dt[i].StartDateTime = moment(new Date(dt[i].StartDateTime)).format('DD-MM-YYYY');
                        dt[i].EndDateTime = moment(new Date(dt[i].EndDateTime)).format('DD-MM-YYYY');
                        dt[i].RateCardName = this.ratecard.itemsFull.find(itm => itm.RateCardID === dt[i].RateCardID) ? this.ratecard.itemsFull.find(itm => itm.RateCardID === dt[i].RateCardID).name : 'N/A';
                        dt[i].CarParkName = this.carpark.itemsFull.find(itm => itm.CarParkID === dt[i].CarParkID) ? this.carpark.itemsFull.find(itm => itm.CarParkID === dt[i].CarParkID).CarParkName: 'N/A';
                        dt[i].CreatedDateTime = moment(new Date(dt[i].CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                        dt[i].UpdatedDateTime = dt[i].UpdatedDateTime != null ? moment(new Date(dt[i].UpdatedDateTime)).format('DD-MM-YYYY hh:mm:ss A') : 'N/A';
                    }
                }
                
                this.items = dt;
                this.tableloading = false;
                this.btn_loading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operators", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let response = await this.$store.dispatch("getAllCarpark2", {});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                    this.carpark.itemsFull.push({name: "All Car Parks", CarParkID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                        this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                        this.carpark.itemsFull.push(dt[i]);
                    }
                }

                this.carpark.selected = this.carpark.items[0].CarParkID;
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getRateCardDetail(){
            let list = [];
            this.ratecardDetail.items = [];
            this.ratecardDetail.selected = "";

            try{
                this.ratecardDetail.loader = true;
                this.ratecardDetail.disable = true;
                let self = this;

                if (this.carpark.selected){
                    let ratecard = this.ratecardDetail.itemsFull.filter(item => {
                    return item.CarParkID == this.carpark.selected;
                    });
                    this.ratecardDetail.items.push({name: "All Rate Cards", RateCardID: ""});
                    for(var i=0; i<ratecard.length; i++){
                        if(rateccard[i].DefaultRateCard !== 1){
                            ratecard[i].name = `${ratecard[i].RateCardID} - ${ratecard[i].RateCardName}`;
                            this.ratecardDetail.items.push({name: ratecard[i].name, RateCardID: ratecard[i].RateCardID});
                            this.ratecardDetail.itemsFull.push(ratecard[i]);
                        }
                    }

                    // this.ratecardDetail.selected = "";
                }

                else{
                    let response = await this.$store.dispatch("getRateCard", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.ratecardDetail.items.push({name: "All Rate Cards", RateCardID: ""});
                        this.ratecardDetail.itemsFull.push({name: "All Rate Cards", RateCardID: ""});

                    for(var i=0; i<dt.length; i++){
                        if(dt[i].DefaultRateCard !== 1){
                            dt[i].name = `${dt[i].RateCardID} - ${dt[i].RateCardName}`;
                            this.ratecardDetail.items.push({name: dt[i].name, RateCardID: dt[i].RateCardID});
                            this.ratecardDetail.itemsFull.push(dt[i]);
                        }
                    }

                    this.ratecardDetail.selected = this.ratecardDetail.items[0].RateCardID;

                        this.ratecardDetail.selected = this.ratecardDetail.items[0].RateCardID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.ratecardDetail.loader = false;
                this.ratecardDetail.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },
        async getRateCard(){
            let list = [];
            this.ratecard.items = [];
            this.ratecard.selected = "";

            try{
                this.ratecard.loader = true;
                this.ratecard.disable = true;
                let self = this;

                if (this.carpark.selected){
                    console.log(this.ratecard)
                    let ratecard = this.ratecard.itemsFull.filter(item => {
                    return item.CarParkID == this.carpark.selected;
                    });
                    console.log(ratecard);

                    this.ratecard.items.push({name: "All Rate Cards", RateCardID: ""});

                    for(var i=0; i<ratecard.length; i++){
                        ratecard[i].name = `${ratecard[i].RateCardID} - ${ratecard[i].RateCardName}`;
                        this.ratecard.items.push({name: ratecard[i].name, RateCardID: ratecard[i].RateCardID});
                        this.ratecard.itemsFull.push(ratecard[i]);
                    }

                    // this.ratecard.selected = "";
                }

                else{
                    let response = await this.$store.dispatch("getSpecialRateCard", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.ratecard.items.push({name: "All Special Rate Cards", RateCardID: "", CarParkID: ""});
                        this.ratecard.itemsFull.push({name: "All Special Rate Cards", RateCardID: "", CarParkID: ""});
                    
                        for(var i=0; i<dt.length; i++){
                            if(this.carpark.items.find(val=> val.CarParkID == dt[i].CarParkID)){
                                dt[i].name = `${dt[i].RateCardID} - ${dt[i].RateCardName}`;
                                this.ratecard.items.push({name: dt[i].name, RateCardID: dt[i].RateCardID, CarParkID: dt[i].CarParkID});
                                this.ratecard.itemsFull.push(dt[i]);
                                console.log(this.ratecard)
                            }
                        }

                        this.ratecard.selected = this.ratecard.items[0].RateCardID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.ratecard.loader = false;
                this.ratecard.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async add(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {
                    carparkid: v.carpark.selected,
                    ratecardid: v.ratecard.selected,
                    startdatetime: v.startdate.itemforapi,
                    enddatetime: v.enddate.itemforapi
                };
                let response = await this.$store.dispatch("createSpecialRateValidation", req);
                if (response.data.code === 'AP000'){
                    await this.getRateCard();
                    await this.listItems(1);
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Special Car Park Rate Created!`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
        },
        async update(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                console.log(v);
                this.swal.scolor = 'green';
                let req = {
                    carparkid: v.carpark.selected,
                    ratecardid: v.ratecard.selected,
                    startdatetime: v.startdate.itemforapi,
                    enddatetime: v.enddate.itemforapi,
                    specialrateid: v.specialrateid,
                    status: v.status
                };
                console.log("response")

                let response = await this.$store.dispatch("updateSpecialRateValidation", req);
                if (response.data.code === 'AP000'){
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Special Car Park Rate Updated!`;
                    await this.listItems(1);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE("AP002");
            }
        },

        async execFilter(type){
            if (type == 'carpark'){
                await this.getRateCard();
                await this.searchTimeOut();
            }

            if (type == 'ratecard'){
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listItems(1);
          }, 800);
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Enable'}
                    else if (val === 0){return 'Disable'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },

        onClosed(value){
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_update = value;
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.add(value);
            }
            if (value.flag === 'update'){
                this.ma.confirm = true;
                this.ma.modal_update = false;
                this.update(value);
            }
        },

        createItem(){
            this.ma.modal = true;
        },

        onUnload(filter, evt){
            AppStorage.set('filter', JSON.stringify(filter));
        },

        horizontalScroll(){
            var wrapper1 = document.getElementById('wrapper1');
            var wrapper2 = document.querySelector('.v-data-table__wrapper');
            document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
            document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

            if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";

            window.addEventListener('resize', function() {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });

            const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;
                console.log('table', document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth);
                console.log('parent', document.getElementById("table-parent").offsetWidth);

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });
            });

            const someEl = document.getElementsByTagName("table")[0];
            myObserver.observe(someEl);


            wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            console.log(row)
            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'view_details'){
                this.ma.modal_viewdetails = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }
          
            if (flag == 'view_subscriberinfo'){
                this.ma.modal_ratecarddetails = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'view_pdf'){
                this.ma.modal_pdf = true;
                this.ma.modalInfo = row;
                this.pdfurl = row.InvoiceReceiptURL;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },
    }
}
</script>