<template>
    <v-row justify="center">
    <v-dialog v-model="dialog_local" max-width="450">
        <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
            <v-card class="mx-auto" tile>
            <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
                Update Special Car Park Rate
                <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
                </v-card-title>
            <v-card-text>
            <v-container style="height: 450px"
            fluid >
                <v-row>
                    <v-col cols="12" md="12">
                        <v-autocomplete
                            dense
                            outlined
                            placeholder="Select"
                            label="Car Park *"
                            v-model="form.carpark.selected"
                            :items="form.carpark.items"
                            @change="execFilter('carpark')"
                            item-text="name"
                            item-value="CarParkID"
                            :rules="form.carpark.rule"
                            :loading="form.carpark.loader"
                            persistent-hint
                            hide-details="auto"
                            disabled
                            >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field
                            dense
                            outlined
                            v-model="modalInfo.SpecialRateCardID"
                            label="Special Rate Card ID"
                            required
                            disabled
                        ></v-text-field>
                        
                        <v-autocomplete
                        dense
                        outlined
                        placeholder="Select"
                        label="Rate Card ID *"
                        v-model="form.ratecard.selected"
                        :items="form.ratecard.items"
                        item-text="name"
                        item-value="RateCardID"
                        :rules="form.ratecard.rule"
                        :loading="form.ratecard.loader"
                        hide-details="auto"
                        disabled
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <dpicker :key="counter1" :datetime="form.startdate" :mode="'date'" @date="dateValStartTime" :close="dialog_local" :place="true" label="Start Date *" :width="'58px'"></dpicker>
                    </v-col>
                    <v-col cols="12" md="12">
                        <dpicker :key="counter2" :datetime="form.enddate" :mode="'date'" @date="dateValEndTime" :close="dialog_local" :place="true" label="End Date *" :width="'58px'"></dpicker>
                        <v-switch
                            color="success"
                            v-model="form.status"
                            @change="showStatus()"
                            :label="`${form.status == 1 ? 'Enabled' : 'Disabled'}`"
                            :disabled="readonly"
                            class="pr-4"
                        ></v-switch>
                        <v-text-field
                            dense
                            outlined
                            v-model="modalInfo.CreatedDateTime"
                            label="Created Date"
                            required
                            disabled
                        ></v-text-field>
                        <v-text-field
                            dense
                            outlined
                            v-model="modalInfo.UpdatedDateTime"
                            label="Updated Date"
                            required
                            disabled
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        
                       
                    </v-col>
                </v-row>
            </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
            </v-card-actions>
            </v-card>
        </v-form>
        <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
        <v-snackbar
            v-model="swal.notification"
            top
            :color="swal.scolor"
            >
                {{ swal.message }}
        </v-snackbar>
    </v-dialog>
    </v-row>
    </template>
    <script>
    import alertbox from './Alert';
    import {ERR} from '../../assets/js/errhandle.js';
    import {rule} from '../../assets/js/checkPerm';
    import dpicker from "../DatePickerComponent";
    import * as moment from "moment";
    import * as tz from "moment-timezone";
    
    export default {
        props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo', 'readonly', 'operator', 'carpark', 'ratecard'],
        
        data()  {
            return {
                dialog_local: false,
                innerModal: false,
                form: {
                    ratecard: {
                        items: [],
                        itemsFull: [],
                        selected: '',
                        loader: false,
                        disable: false,
                        rule: []
                    },
                    spfeepercentage: {item: '', rule: []},
                    startdate: {item: '', itemforapi: '', rule: []},
                    enddate: {item: '', itemforapi: '', rule: []},
                    operator: {
                        items: [],
                        itemsFull: [],
                        selected: '',
                        loader: false,
                        disable: false,
                        rule: []
                    },
                    carpark: {
                        items: [],
                        itemsFull: [],
                        selected: '',
                        loader: false,
                        disable: false,
                        rule: []
                    },
                },
    
                btn_loading: false,
    
                roles: [],
                ma: {
                    modal: false,
                    modal_action_type: '',
                    modalInfo: [],
                    customText: [],
                    edit_modal: false,
                    view_modal: false,
                    confirmEdit: false,
                    confirmCreate: false,
                },
                swal: {
                    notification: false,
                    message: '',
                    scolor: '',
                },
                modal_loader: false,
                counter1: 0,
                counter2: 0
            }
        },
    
        components: {alertbox, dpicker},
    
        watch: {
            dialog: {
            handler: async function(value) {
                await this.modalHandler();
                if(value == true){
                    this.modal_loader = true;
                    await this.handleValue();
                    this.modal_loader = false;
                    this.btn_loading = false;
                }
            },
            },
    
            dialog_local: {
            handler: function(value) {
                this.modalCloseHandler();
            },
            },
        },
    
        methods: {
            async onRuleChange(after, before) {
                this.form.ratecard.rule = [];
                this.form.carpark.rule = [];
                this.form.spfeepercentage.rule = [];
                this.form.startdate.rule = [];
                this.form.enddate.rule = [];
            },
    
            async beforeCreateForm(flag){
                this.form.ratecard.rule = [
                    v => !!v || 'This is required',
                ];
    
                this.form.carpark.rule = [
                    v => !!v || 'This is required',
                ];
    
                this.form.spfeepercentage.rule = [
                    v => !!v || 'This is required',
                    v => !Number.isNaN(Number(v)) || 'Only integer is allowed'
                ];
    
                this.form.startdate.rule = [
                    v => !!v || 'This is required',
                ];
    
                this.form.enddate.rule = [
                    v => !!v || 'This is required',
                ];
    
                //must set this in order to use 'this' as 'self' inside settimeout etc..
                let self = this;
    
                setTimeout(function () {
                    //self.$refs.image.resetValidation();
                    //self.$refs.image.reset();
    
                 if (self.$refs.ct.validate()) {
                        self.innerModal = true;
                        self.ma.modalInfo = {id: 1, name: 2};
                        self.ma.modal_action_type = flag;
                    }
                });
            },
    
            validateAccess(val){
                let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
                return rule.checkaccess(val, access);
            },
    
            modalHandler(){
                this.dialog_local = !this.dialog_local;
            },
    
            modalCloseHandler(){
                if (this.dialog_local == false){
                    this.$emit('closed', false);
                }
            },
    
            async handleValue(){
                this.counter1++;
                this.counter2++;
                this.onRuleChange();

                console.log(this.modalInfo)

                let operator = [];
                operator.push({name: 'Select', OperatorID: ''});
                let filteredoperator = this.operator.filter(item => item.OperatorID !== "");
                for (const [index, item] of filteredoperator.entries()){
                    operator.push(item);
                }
    
                this.form.operator.items = operator;
                this.form.operator.selected = "";
    
                let carpark = [];
                //carpark.push({name: 'Select', CarParkID: ''});
                carpark.push({name: 'Select', CarParkID: ''});
                let filteredcarpark = this.carpark.filter(item => item.CarParkID !== "");
                for (const [index, item] of filteredcarpark.entries()){
                    carpark.push(item);
                }
    
                this.form.carpark.items = carpark;
                this.form.carpark.selected = this.modalInfo.CarParkID;
                this.form.status = this.modalInfo.Status;
    
                let ratecard = [];
                ratecard.push({name: 'Select', RateCardID: ''});
                let filteredratecard = this.ratecard.filter(item => item.RateCardID !== "");
                for (const [index, item] of filteredratecard.entries()){
                    ratecard.push(item);
                }
    
                this.form.ratecard.items = ratecard;
                this.form.ratecard.selected = this.modalInfo.RateCardID;
    
                this.form.spfeepercentage.item = "";
                this.form.startdate.itemforapi = this.modalInfo.StartDateTime;
                this.form.startdate.item = moment(this.modalInfo.StartDateTime.split('-').reverse().join('-')).format('MM-DD-YYYY');
                this.form.enddate.itemforapi = this.modalInfo.EndDateTime;
                this.form.enddate.item = moment(this.modalInfo.EndDateTime.split('-').reverse().join('-')).format('MM-DD-YYYY');
                console.log(this.form.enddate)
                this.counter1++;
                this.counter2++;
            },
    
            handleDelete(value){
                this.innerModal = true;
                this.ma.modal_action_type = value;
            },
    
            execFilter(typ){
                if (typ == 'operator'){
                    this.form.carpark.items = [];
                    this.form.carpark.selected = '';
    
                    if (this.form.operator.selected){
                        let itm = this.carpark.filter(i => i.OperatorID == this.form.operator.selected);
    
                        //this.form.carpark.items.push({name: 'Select', CarParkID: ''});
                        this.form.carpark.items.push({name: 'Select', CarParkID: ''});
    
                        for(let i=0; i<itm.length; i++){
                            this.form.carpark.items.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                        }
                    }
    
                    else{
                        //this.form.carpark.items.push({name: 'Select', CarParkID: ''});
                        this.form.carpark.items.push({name: 'Select', CarParkID: ''});
                        let itm = this.carpark;
                        for(let i=0; i<itm.length; i++){
                            this.form.carpark.items.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                        }
                    }
                }
    
                if (typ == 'carpark'){
                    this.form.ratecard.items = [];
                    this.form.ratecard.selected = '';
    
                    if (this.form.carpark.selected){
                        let itm = this.ratecard.filter(i => i.CarParkID == this.form.carpark.selected);
    
                        this.form.ratecard.items.push({name: 'Select', RateCardID: ''});
    
                        for(let i=0; i<itm.length; i++){
                            this.form.ratecard.items.push({name: itm[i].name, RateCardID: itm[i].RateCardID});
                        }
                    }
    
                    else{
                        this.form.ratecard.items.push({name: 'Select', RateCardID: ''});
                        let itm = this.ratecard;
                        for(let i=0; i<itm.length; i++){
                            this.form.ratecard.items.push({name: itm[i].name, RateCardID: itm[i].RateCardID});
                        }
                    }
                }
            },
    
            //set the action to be sent to watcher to execute the create/edit function
            async onConfirm(value){
                    if (this.ma.modal_action_type == 'update'){
                        this.form.flag = 'update';
                        this.form.startdate.itemforapi = moment(new Date(this.form.startdate.item).setHours(0, 0, 0, 0)).utc().format('YYYY-MM-DD HH:mm:ss');
                        this.form.enddate.itemforapi = moment(new Date(this.form.enddate.item).setHours(0, 0, 0, 0)).utc().format('YYYY-MM-DD HH:mm:ss');
                        this.form.specialrateid = this.modalInfo.SpecialRateCardID
                        this.$emit('confirm', this.form);
                    }
    
                    this.innerModal = false;
            },
    
            onClosed(value){
                if (value == false){
                    this.innerModal = value;
                }
            },
    
            dateValStartTime(value){
                this.form.startdate.item = value;
                // this.form.enddate.item = value;
            },
    
            dateValEndTime(value){
                this.form.enddate.item = value;
                console.log(this.form.enddate.item)
            },
            showStatus(){
            if (this.form.status == '0'){
                // this.form.carparkmanagedbaycount = '';
                // this.form.rule = [];
                // this.form[64].bayprefix = '';
                // this.form[64].rule = [];
            }
        },
        }
    }
    </script>